<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModalForm"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            v-if="typeNewCreateSW029!==null"
            class="mb-0"
          >
            {{ typeNewCreateSW029.txt }}
          </h5>
          <h5
            v-else
            class="mb-0"
          >
            {{ $t('Novo familiar') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <validation-observer
          #default="{ }"
          ref="formSaveValidatorSW029"
        >
          <b-form
            @submit.prevent
          >
            <vue-perfect-scrollbar
              class="b-sidebar-scrollarea-form"
              :settings="configSettingsScroll"
            >
              <b-container
                class="pt-2 pb-0 pl-2 pr-2"
                fluid
              >
                <b-row
                  v-show="typeNewCreateSW029===null"
                >
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Tipo')"
                    >
                      <v-select
                        v-model="typeNewCreateSW029"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="typeNewSW029"
                        append-to-body
                        :calculate-position="withPopper"
                        label="txt"
                        item-text="txt"
                        item-value="id"
                        @change="changeTypeNewCreateSW029"
                        @input="changeTypeNewCreateSW029"
                      >
                        <template #option="{ txt }">
                          {{ txt }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <component
                      :is="templateNewCreateSW029"
                      ref="formSave"
                      :data="loadSW029"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </vue-perfect-scrollbar>

            <div
              class="d-flex p-1 justify-content-center"
            >
              <b-button
                v-if="typeNewCreateSW029!==null"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                type="submit"
                @click.stop.prevent="saveFormSW029"
              >
                {{ $t('Guardar') }}
              </b-button>
              <b-button
                v-if="typeNewCreateSW029!==null"
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mr-1"
                @click.stop.prevent="clearFormSW029"
              >
                {{ $t('Limpar') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click.stop.prevent="hide"
              >
                {{ $t('Fechar') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BFormGroup, BButton, BRow, BCol, BForm, BContainer,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { ValidationObserver } from 'vee-validate'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BRow,
    BCol,
    BForm,
    BContainer,
    vSelect,
    ValidationObserver,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
  },
  data() {
    return {
      typeNewCreateSW029: null,
      templateNewCreateSW029: null,
      loadSW029: {},
    }
  },
  methods: {
    changeTypeNewCreateSW029(row) {
      this.templateNewCreateSW029 = null
      try {
        if (row.template) {
          this.$root.$emit('app::loading', true)

          const templateLoad = () => import(`@/views/modules/crm/leads/form/form_data/sw029/components/${row.template}`)
          this.templateNewCreateSW029 = templateLoad

          this.$nextTick(() => {
            this.$root.$emit('app::loading', false)
          })
        }
      } catch (err) {
        //
      }
    },
    closeModalForm(val) {
      this.$store.dispatch('sw029/setShowFormCreateNew', val).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async saveFormSW029() {
      const self = this

      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true
        await this.$refs.formSaveValidatorSW029.validate()
          .then(valid => {
            if (valid) {
              this.$refs.formSave.getDataSaveForm().then(async data => {
                const oData = data
                oData.type = this.typeNewCreateSW029

                // Se for do tipo "Familiar", vai verificar se o telefone / email ja pertence a algum contacto existente
                if (Number(oData.type.id) === 2) {
                  if ((oData.email !== '') || ((oData.telefone !== '') && (oData.telefone.length > 4))) {
                    self.$root.$emit('app::loading', true)

                    await this.$store.dispatch('sw029/checkEmailPhoneFamiliar', oData).then(async res => {
                      self.$root.$emit('app::loading', false)
                      if (res.lead && res.leadname) {
                        await this.$swal({
                          title: '',
                          html: `${this.$t('Foi encontrado o Lead {leadname} na sua base de dados, pretende adicionar como Lead familiar?', { leadname: `<b>${res.leadname}</b>` })}`,
                          icon: 'error',
                          showCancelButton: true,
                          reverseButtons: true,
                          confirmButtonText: this.$t('Sim'),
                          cancelButtonText: this.$t('Não'),
                          customClass: {
                            confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
                            cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
                          },
                          buttonsStyling: false,
                        }).then(result => {
                          if (result.value) {
                            // Altera o tipo de registo ao guardar e o id do lead
                            oData.type = this.typeNewSW029.find(o => o.id === '1')
                            oData.lead = Number(res.lead)
                          }
                        })
                      } else if (res.contacto && res.contactoname) {
                        await this.$swal({
                          title: '',
                          html: `${this.$t('Foi encontrado o contacto {contactoname} na sua base de dados, pretende adicionar como contacto familiar?', { contactoname: `<b>${res.contactoname}</b>` })}`,
                          icon: 'error',
                          showCancelButton: true,
                          reverseButtons: true,
                          confirmButtonText: this.$t('Sim'),
                          cancelButtonText: this.$t('Não'),
                          customClass: {
                            confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
                            cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
                          },
                          buttonsStyling: false,
                        }).then(result => {
                          if (result.value) {
                            // Altera o tipo de registo ao guardar e o id do contacto
                            oData.type = this.typeNewSW029.find(o => o.id === '3')
                            oData.lead = Number(res.contacto)
                          }
                        })
                      }
                    }).catch(error => {
                      self.$root.$emit('app::loading', false)
                      throw error
                    })
                  }
                }

                self.$root.$emit('app::loading', true)

                await this.$store.dispatch('sw029/saveSW029', oData).then(res => {
                  self.$root.$emit('app::loading', false)
                  self.showMsgSuccessRequest(res)
                  self.closeModalForm()
                  self.$parent.init()
                }).catch(error => {
                  self.$root.$emit('app::loading', false)
                  self.showMsgErrorRequest(error)
                })
              }).catch(error => {
                if (error) {
                  this.showMsgErrorRequest(error)
                }
              })
            } else {
              this.showMsgErrorRequest(new Error(this.$t('Preencha os campos obrigatórios')))
            }
          })
          .catch(() => {
            this.showMsgErrorRequest(new Error(this.$t('Preencha os campos obrigatórios')))
          })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
    clearFormSW029() {
      this.$refs.formSave.clearForm()
      this.$refs.formSaveValidatorSW029.reset()
    },
    loadDataForm(sw029) {
      this.loadSW029 = sw029
      this.typeNewCreateSW029 = this.typeNewSW029.find(o => o.id === this.loadSW029.sw029s11)
      this.changeTypeNewCreateSW029(this.typeNewCreateSW029)
    },
  },
  setup() {
    const { t } = useI18nUtils()

    const typeNewSW029 = []
    typeNewSW029.push({
      id: '1',
      txt: t('Lead familiar'),
      template: 'lead.vue',
    })
    typeNewSW029.push({
      id: '3',
      txt: t('Contacto familiar'),
      template: 'contact.vue',
    })
    typeNewSW029.push({
      id: '2',
      txt: t('Familiar'),
      template: 'manual.vue',
    })

    return {
      typeNewSW029,
      configSettingsScroll,
    }
  },
}
</script>
